import notify from './notify';
import validator from './validator';
import recaptcha from './recaptcha';
import i18next from './i18next';

window.shopline = Object.assign(window.shopline || {}, {
  notify,
  validator,
  recaptcha,
  i18next,
});
