import country from './country/zh-cn';

export default {
  translation: {
    ...country,
    'Express checkout': '快速结帐',
    'Or fill in the information': '或填写信息',
    'Form Validation - required': '{{fieldName}} 是必须的',
    'Form Validation - max': '{{fieldName}} 太长',
    'Form Validation - maxLength': '{{fieldName}} 太长',
    'Form Validation - min': '{{fieldName}} 太短',
    'Form Validation - pattern': '{{fieldName}} 无效',
    'Form Validation - validate': '{{fieldName}} 无效',
    'Form Validation - validate - minAge': '生日无效，需大于 {{minAge}} 岁',
    'Form Validation - phone - validate - pattern':
      '{{fieldName}} 无效，请重新确认',
    'Form Validation - terms': '你必须同意网站服务条款及隐私政策',
    'Form Action Deleting': '删除中',
    IAgreeTerms: '我同意网站<0>服务条款</0>及<2>隐私政策</2>',
    'Terms of Use and Privacy Policy and Age Policy':
      '我同意网站<0>服务条款</0>及<2>隐私权政策</2>，且我已满 {{ age }} 岁',
    Terms: '<0>服务条款</0>及<2>隐私政策</2>',
    'Ask Signin': '以会员身分快速结帐？',
    'Member Login': '会员登入',
    Login: '登入',
    'Customer Information': '顾客资料',
    'Full Name': '顾客名称',
    'Email Address': '电子信箱',
    'Email Address Optional': '电子信箱 (选填)',
    'Create an account with shop': '成为 {{shopName}} 的会员',
    'Send email updates and promotions':
      '我愿意接收 {{merchantName}} 的最新消息、优惠及服务推广相关资讯',
    'Shipping Address': '送货资料',
    'Recipient Name': '收件人名称',
    'Recipient Contact Number': '收件人电话号码',
    'Customer Contact Number': '顾客电话号码',
    'Address Line 1': '地址1',
    'Address Line 1 DE': '地址 1',
    'Address Line 2': '地址2 (选填)',
    'Address Line 2 DE': '地址2 (选填)',
    Address: '地址',
    'Shipping Destination': '送货地点',
    'US Shipping Destination': '送货地点',
    City: '城市/市镇',
    State: '州/省/地区',
    'US State': '州/省/地区',
    Zipcode: '邮政编码',
    'Zipcode GB': '邮政编码',
    'Zipcode FR': '邮递区号',
    'Zipcode DE': '邮递区号',
    'Payment Detail': '付款资料',
    'Payment Fee': '附加费',
    'Total Order Over Hint': '此订单须符合最低金额 {{amount}}，才能进行结帐。',
    'Delivery Method': '送货方式',
    'Delivery Fee': '运费',
    'Remarks for Shop': '其他信息',
    'Remarks for Shop Placeholder': '有什么想告诉卖家吗？ (选填)',
    'Cart Title': '商品清单',
    'Cart Total': '合计',
    'Cart Subtotals subtotal': '小计',
    'Cart Subtotals discount': '折扣',
    'Cart Subtotals total_tax_fee': '税费',
    'Cart Subtotals applied_user_credits': '折抵购物金',
    'Cart Subtotals delivery_fee': '运费',
    'Cart Subtotals payment_fee': '附加费',
    'Cart Subtotals custom_discount': '自订折扣合计',
    'Cart Subtotals applied_member_point_redeem_to_cash': '使用点数折现',
    'Cart delivery_fee Free': '免运',
    'Cart delivery_fee Partial Free': '部分免运',
    'Cart Coupon code apply success': '已使用优惠券',
    'Cart Affiliate code apply success': '推荐人已套用',
    'Place Order': '提交订单',
    'Promotion Label - Promotion': '优惠促销',
    'Promotion Label - Subscription Promotion': '定期购优惠',
    'Promotion Label - Credit Reward': '购物金回馈',
    'Promotion Label - Bundle Pricing': '任选优惠',
    'Promotion Label - Bundle Group': 'A+B 组合',
    'Promotion Label - Buy X Get Y': '买Ｘ享Ｙ',
    'Promotion Label - Member Point Redeem Gift': '兑换活动',
    'Promotion Label - Member Tier Promotion': '会员优惠',
    'Custom Discount Item Label': '自订折扣',
    'Max Discount Price custom_discount': '最多折扣',
    'Apply Code': '验证',
    'Enter Coupon Code': '使用优惠代码',
    'Enter Affiliate Code': '使用推荐代码',
    'Free Checkout': '免费结帐',
    'Form Error - Not Found Address': '抱歉，你所填写的地址暂时不在配送范围',
    'Form Error - Not Found Delivery':
      '抱歉，你所填写的地址暂时不在配送范围，请重新输入后再选择送货方式',
    'Form Error - Card Type Not Support': '暂不支援您使用的信用卡别种类',
    'Credit Card Holder Name': '持卡人名字',
    'Credit Card Number': '信用卡号码',
    'Credit Card Expiry Date': '有效日期 (MM/YY)',
    'Credit Card CVC': '安全码 (CVC)',
    'SlPayment Credit Card Holder Name Hint': '与信用卡上名称相同',
    'SlPayment Credit Card Expiry Date': '有效日期',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      '对不起，订单出现问题，请检查资料填写正确後再试或联络店家。谢谢！',
    'Payment 3D Verification Failed':
      '对不起，3D 验证失败导致订单失败，请再试一次或选择其他付款方式，谢谢！',
    'CVC Hint': '一般信用卡於卡背後显示3位安全码',
    'Product Quantity Validation - Sold Out': '售完，<1>请移除商品</1>',
    'Product Quantity Validation - Invalid Format': '需 ≥ 1',
    'Product Quantity Validation - Low Inventory': '库存只剩 {{ value }} 件',
    'Product Quantity Validation - Purchase Limit':
      '每笔订单限购 {{ value }} 件',
    'Product Quantity Validation - Quantity Over Inventory': '超过库存',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      '每位顾客限购 {{ limit }} 件',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      '每位顾客限购 {{ limit }} 件\n您只能再购买 {{ left }} 件',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      '每位顾客限购 {{ limit }} 件\n您已购买达上限',
    'Product Quantity Validation - Invalid': '已失效，<1>请移除商品</1>',
    'Product Quantity Validation - Insufficient Point':
      '点数不足<1>请移除商品</1>',
    'Points used to redeem gifts': '点数兑换赠品',
    'Points earned after days': '取货後 {{number}} 天获得点数',
    'Points earned after order completed': '订单完成后获得点数',
    'Credits earned after order completed': '订单完成后获得购物金',
    Points: '点',
    Credits: '购物金',
    'Free Gift': '赠品',
    'Remove Product Popup - title': '移除商品',
    'Remove Product Popup - content': '确定要移除 {{ itemName }} 吗？',
    'Remove Product Popup - cancel': '取消',
    'Remove Product Popup - remove': '移除',
    'Add-On Item Validation - Purchase Limit': '加购品数量不可超过主商品',
    'Product(s) ordered from livestream are reserved':
      '直播商品限时保留库存，倒数<0>{{ countdown }}</0>把握机会带回家！',
    'Live Stream limited time reservation': '直播限时保留 {{ qty }}件',
    'Live Stream limited time reservation (separator)': '、',
    'Live Stream limited time reservation (unit)': '{{ qty }}件',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `保留至 {{ endDateFormat }}
      ({{ startDateFormat }} 开始的直播：
      {{ title }})
      根据你的当地时区显示 (GMT{{ offset }})`,
    'Inclusive Tax Hint': '已含税 {{taxName}} {{taxRate}}%：{{taxAmount}}',
    'SL FPX Payment Hint':
      '您正在使用 SHOPLINE Payments 结帐，完成付款代表您同意 SHOPLINE Payments 的<a href="/about/terms" target="_blank">服务条款</a>及FPX的<a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">服务条款</a>',
    'SL FPX Checkout Term':
      '下一步将会连接到银行页面进行付款，完成后请记得按下确认付款，返回订单页面确认订购完成。',
    'Select Bank': '请选择银行',
    'Field (optional)': '{{fieldName}} (选填)',
    'Field - gender': '性別',
    'Field - birthday': '生日',
    'Gender - male': '男',
    'Gender - female': '女',
    'Gender - other': '不透露',
    'Birthday - hint': '您需达到一定年龄才能在网店注册及购物。',
    'Birthday - year': '年',
    'Birthday - month': '月',
    'Birthday - date': '日',
    'Saved Addresses - new': '使用新地址',
    'Saved Addresses - default': '预设地址',
    'Saved Addresses - saved address': '已储存地址',
    'Saved Addresses - save address': '储存这个送货地址',
    'Add To Cart': '加入购物车',
    'Add To Cart Success': '已经成功加入到购物车',
    'Addon Reminder': '下方还有商品可前往加购',
    'Addon Limit': '限购 {{limit}} 件',
    'Reach Purchase Limit': '已达购买上限',
    'Sold Out': '售完',
    'Addon Min Price': '订单小计满 {{minPrice}} 即以优惠价加购下列商品！',
    'Addon Item': '加购品',
    'Cart Over Limit Error': '商品总数的上限为 100 件，请调整数量再点击购买',
    Cancel: '取消',
    Continue: '继续',
    'Auto Fill - Title': '输入手机以进行快速结帐',
    'Auto Fill - Phone Error': '请输入正确的格式',
    'Auto Fill - Toast': '没有侦测到上一笔订单的纪录，将不会带入快速结帐资料',
    QuickRegistrations_creditValueDescription:
      '请协助填写以下会员资讯，填写完毕将可获得 ${{ creditValue }} 购物金',
    QuickRegistrations_isVerified: '已验证',
    QuickRegistrations_isUnverified: '未验证',
    QuickRegistrations_Input_Phone_Invalid_Format: '请输入正确的手机格式',
    QuickRegistrations_Input_Email_Invalid_Format: '请输入正确的电邮地址',
    QuickRegistrations_Input_Password_Required: '网店登入密码必填',
    QuickRegistrations_Input_Password_Too_Short: '密码太短',
    QuickRegistrations_Input_Password_Too_Long: '密码太长',
    QuickRegistrations_Input_Password_Complexity:
      'Password 最少8个字元（不能有空格\nPassword 最少1个大写英文字母\nPassword 最少1个小写英文字母\nPassword 最少1个数字\nPassword 最少1个特殊符号(例 ! # $ ^ *)',
    QuickRegistrations_Input_Phone_Placeholder: '请输入手机(必填)',
    QuickRegistrations_Input_Email_Placeholder: '请输入Email',
    QuickRegistrations_Input_Password_Placeholder: '网店登入密码 (至少 8 字元)',
    QuickRegistrations_Input_Name_Placeholder: '请输入全名(必填)',
    QuickRegistrations_Input_Phone_Label: '手机号码',
    QuickRegistrations_Input_Email_Label: 'Email',
    QuickRegistrations_Input_Password_Label: '密码',
    QuickRegistrations_Input_Name_Label: '会员姓名',
    QuickRegistrations_Input_Birthday: '生日',
    QuickRegistrations_Input_Birthday_Year_Placeholder: '年',
    QuickRegistrations_Input_Birthday_Month_Placeholder: '月',
    QuickRegistrations_Input_Birthday_Day_Placeholder: '日',
    QuickRegistrations_Input_Gender_Female: '女性',
    QuickRegistrations_Input_Gender_Male: '男性',
    QuickRegistrations_Input_Gender_Other: '不透露',
    QuickRegistrations_Required_Input_Placeholder: '{{ field }}(必填)',
    QuickRegistrations_Submit: '送出',
    QuickRegistrations_Modal_getCreditSuccess: '成功领取购物金',
    QuickRegistrations_Modal_getCreditAlready: '已领取过购物金',
    QuickRegistrations_Modal_BindingLINEFailed_title: '绑定失败',
    QuickRegistrations_Modal_BindingLINEFailed_body:
      '此 LINE 帐号曾绑定过官方 LINE 帐号，无法再次绑定',
    QuickRegistrations_Modal_BindingLINEFailed_confirm: '确定',
    QuickRegistrations_already_used: '已被其他会员使用',
    QuickRegistrations_custom_field_is_required: '{{ fieldName }} 为必填',
    QuickRegistrations_minimum_age_limit: '年齡限制須大於 {{ age }} 歲',
    QuickRegistrations_Member_Info_Hint:
      '填写以下会员信息：{{ fields }}，填写完毕可获得 {{ rewards }}',
    QuickRegistrations_Member_Info_Sent: '待信息检查完成，将发放会员信息奖赏！',
    QuickRegistrations_Member_Info_Symbol: '、',
    QuickRegistrations_Member_Info_Name: '姓名',
    QuickRegistrations_Member_Info_Email: '电邮',
    QuickRegistrations_Member_Info_Mobile: '手机号',
    QuickRegistrations_Member_Info_Gender: '性别',
    QuickRegistrations_Member_Info_Birthday: '生日',
    QuickRegistrations_Member_Info_User_Credits: '${{ count }} 元购物金',
    QuickRegistrations_Member_Info_Member_Points: '{{ count }} 点会员点数',
    QuickRegistrations_Member_Info_Coupons: '{{ count }} 张优惠券',
  },
  widget: {
    Subscribed: '订阅成功',
    'Invalid email': 'email 格式有误',
    'Please try again': '请重试一次',
    'You have requested too many times, please try again later':
      '您已執行多次，请稍后再试。',
    'Clear All Filter': '清除全部',
  },
};
